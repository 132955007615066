<template>
  <div class=container>
      <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td>
              <div align="center"><font size="+2">
                                </font>              </div>
              <br>
<h3>About the Database</h3>
              Traditional
computer graphics rendering generally assumes that the appearance of
surfaces remains static over time. Yet, there are a number of natural
processes that cause surface appearance to vary dramatically, such as
burning of wood, wetting and drying of rock and fabric, decay of fruit
skins, or corrosion and rusting of steel and copper. To investigate
time-varying surface appearance, we formulate this problem as TSV-BRDF
(Time-and-Space-Varying BRDF), <br>
<br>
<div style="text-align: center;"><a href="https://cave.cs.columbia.edu/old/databases/staf/img/tsvbrdf.png"></a><br>
  <a href="https://cave.cs.columbia.edu/old/databases/staf/img/tsvbrdf.png"><img src="https://cave.cs.columbia.edu/old/databases/staf/images/tsvbrdf_thumb.png" width="360" height="270" border="0"></a></div>
<br>
<br>
and used a <router-link to="/repository/STAF/documentation">dome</router-link> to
acquire a database of 26
examples covering a range of phenomena, including burning, corrosion,
drying on smooth and rough surfaces, and decay. The following image is
a brief overview:<br>
<br>
<div style="text-align: center;"><a href="https://cave.cs.columbia.edu/old/databases/staf/img/database.png"></a><a href="https://cave.cs.columbia.edu/old/databases/staf/img/database.png"><img src="https://cave.cs.columbia.edu/old/databases/staf/images/database_thumb.png" width="360" height="270" border="0"></a></div>
<br>
<br>
Basically, there are 3 steps to acquire and process each example:<br>
<br>
<ul>
  <li>First,&nbsp;at each time step,
    we have acquired images from 16 views and 80 lighting directions from
    the dome (thus
    1,280 measurement in total). All of the acquired images are high
    dynamic range by taking two images under different exposures. For
    each example, we usually captured 30 time steps. Then we performed both
    geometrical and radiometrical
    normalization so that the acquired images are normalized to
    &nbsp;400*400 pixels (other resolution as well) for each lighting
    and view direction with perfect uniform white lighting.</li>
  <br>
  <li>Second,&nbsp; we fitted a simple BRDF model for each
    pixel at each
    time step. The BRDF model we used are Lambertian + a simplfied
    Torrance-Sparrow model. With this fitting, the reflectance of each
    sample at each time step will be
    represented by a set of BRDF parameters.</li>
  <br>
  <li>Finally, we proposed a novel nonlinear STAF&nbsp;
    (Space-Time
    Appeareance Factorization) model to represent the TSV-BRDF, which not
    only can accurately reconstruct the original data but also gives us
    much more controllability for novel rendering applications. </li>
</ul>
<br>
The release of this database thus includes the above 3 parts for each
example: the raw measurement, the fitted BRDF parameters, and the STAF
factorization results. Due to the space limitation, we can not release
all the
1,280 raw measurements. Instead, we selected 1 view and 6 lighting
directions (thus 6 measurement in total) for each example at each time
step. More detailed information about the database can be found <router-link to="/repository/STAF/db">here</router-link>.<br>
<br>
The acquisition and the modeling details of our work can be referred to
the following paper:<br>
<br>
<blockquote>
<!--?
print_paperentry_byid($db,"250",false);
?-->
</blockquote>
</td>
          </tr>
          <tr>
            <td><hr style="width: 100%; height: 2px;">
              <router-link to="/repository/STAF">STAF
Database Home</router-link><br>
Contact:&nbsp;<a href="mailto:%20staf@lists.cs.columbia.edu">staf@lists.cs.columbia.edu</a><br>
Last modified: 08/28/2006 </td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>